const projects = [
  {
    'date': 'October 2021',
    'title': 'convo',
    'hackathon': "HackDFW 2021",
    'awards': 'Awards: Third Place',
    'description': 'convo is a simple approach to solving this problem using low cost modern-day solutions to allow for two main demographics of people to easily and effectively read books and accomplish additional educative tasks.',
    'hasLink1': true,
    'link1': 'https://devpost.com/software/convo-2407nm',
    'link1Title': 'Devpost',
    'hasLink2': false,
    'link2': '',
    'link2Title': '',
    'hasDivider' : true,
    'image': 'convo.jpeg',
  },
  {
    'date': 'November 2020',
    'title': 'Magpie',
    'hackathon': "Twitter's Codechella 2020",
    'awards': 'Awards: 1st Place, Invite to Chat with CEO Jack Dorsey',
    'description': 'Magpie is a simple, yet effective Google Chrome extension which allows Twitter users to easily get informed and get access to resources, such as news articles, related to tweets they engage with.',
    'hasLink1': true,
    'link1': 'https://devpost.com/software/magpie-mylz6q',
    'link1Title': 'Devpost',
    'hasLink2': true,
    'link2': 'https://www.youtube.com/watch?v=9fA8w2Ap0Q0',
    'link2Title': 'YouTube',
    'hasDivider' : true,
    'image': 'magpie.png',
  },
  {
    'date': 'September 2020',
    'title': 'DoubleCheck',
    'hackathon': 'ShellHacks 2020',
    'awards': 'Awards: Best Use of Google Cloud (COVID-19 Hackathon Fund) by Google',
    'description': 'With DoubleCheck, we use the power of extended reality to advocate for students who are online in hybrid classes or feeling like they aren’t heard. Students can submit a question through our app, which is then sent to an Amazon Alexa. The teacher can then call on the Alexa and have the question read out loud to the class. The teacher can also make polls through the Alexa for students to answer in the mobile app. The teacher can see the results on the web app.',
    'hasLink1': true,
    'link1': 'https://devpost.com/software/doublecheck-jshpy0',
    'link1Title': 'Devpost',
    'hasLink2': true,
    'link2': 'https://www.youtube.com/watch?v=dUNTECyJo1A',
    'link2Title': 'YouTube',
    'hasDivider' : true,
    'image': 'doublecheck.png',
  },
  {
    'date': 'September 2020',
    'title': 'PrankMe.Me',
    'hackathon': 'PennApps XXI',
    'awards': '',
    'description': 'PrankMe is a fully customizable, one-stop-shop for all pranking needs. We have automated text messages, fake phone calls, memes, and more!',
    'hasLink1': true,
    'link1': 'https://devpost.com/software/error-404-project-title-invalid',
    'link1Title': 'Devpost',
    'hasLink2': true,
    'link2': 'https://www.youtube.com/watch?v=klB3f0SGGpw',
    'link2Title': 'YouTube',
    'hasDivider' : true,
    'image': 'prankme.png',
  },
  {
    'date': 'May 2020',
    'title': 'BoxBro',
    'hackathon': 'BrotherHackathon 2020',
    'awards': 'Awards: Third Place',
    'description': 'BoxBro is a cross platform app that is the ultimate storage management solution. It utilizes forefront technology, and it makes it easy to print labels that digitally store the contents of each box. The app allows users to sign in with the Google account using OAuth and create an account. From there, they easily manage, create, delete and share their boxes through the app. User can enter details about their box such as a title, description, location, and a list of items that are in the box. Also, they can upload an image from their camera or photo library that is then save and associated with that box. Once the box is created, the app can automatically connect to their Brother label printer and print a label with a QR code and items list of that box in or to be easily scanned and redirected to the box. They can easily search for items located in any of their boxes as well for quick and easy access.',
    'hasLink1': true,
    'link1': 'https://play.google.com/store/apps/details?id=com.yonathanzetune.boxbro',
    'link1Title': 'Play Store',
    'hasLink2': true,
    'link2': 'https://www.youtube.com/watch?v=4HMz6GsjAJg',
    'link2Title': 'YouTube',
    'hasDivider' : true,
    'image': 'boxbro.png',
  },
  {
    'date': 'April 2020',
    'title': 'terra',
    'hackathon': 'EarthxHack 2020',
    'awards': 'Awards: Third Place, National Geographic Challenge',
    'description': "With so much information on how society needs to act according to climate statistics and environmental statements, it's sometimes hard to understand how someone, as an individual, should act and can contribute, especially for the younger demographic of adults and teens. That's what we sought out to accomplish with terra. terra is a complete platform which gives people the opportunity to share their personal contributions towards helping the environment. Whether it's through volunteering at a recycling plant or just cleaning up your local park, terra is where all creators and activists can go, no matter their demographic or popularity. This is terra, a social network that reaches out to friends, connects you with activities in your community, and allows you to challenge others on the platform as well in order to earn points and claim them in exchange for a donation to your favorite environmental foundation. Challenge, Change, Compete. This is terra.",
    'hasLink1': true,
    'link1': 'https://devpost.com/software/terra-j1fwhl',
    'link1Title': 'Devpost',
    'hasLink2': true,
    'link2': 'https://www.youtube.com/watch?v=RCPPRbW1Fzc',
    'link2Title': 'YouTube',
    'hasDivider' : true,
    'image': 'terra.jpg',
  },
  {
    'date': 'March 2020',
    'title': 'Apartly',
    'hackathon': 'RowdyHacks 2020',
    'awards': 'Awards: Best UI/UX',
    'description': "Our parents and grandparents sacrificed so much for us to create the world we live in today. In today's time, our loved ones are at high risk due to COVID-19. The elderly at nursery homes are stuck being isolated in their small rooms - day after day - night after night. It hurts to know that we can't visit them during these stressful times when they need the social comfort the most. That's why we wanted to create a solution that allows for a sense of community. Thus we made Apartly - you might be physically apart, but not socially. You're just Apartly! Apartly provides an easy platform for the elderly to find communities and similar hobby interest groups and join/host Zoom calls with them. With a simple UI, anyone can use it to find a group of people who all just want to hang virtually!",
    'hasLink1': true,
    'link1': 'https://devpost.com/software/apartly',
    'link1Title': 'Devpost',
    'hasLink2': true,
    'link2': 'https://www.youtube.com/watch?v=TpeIkEh95Xg&feature=emb_title&ab_channel=YonathanZetune',
    'link2Title': 'YouTube',
    'hasDivider' : true,
    'image': 'apartly.jpg',
  },
  {
    'date': 'February 2020',
    'title': 'Braille-iant',
    'hackathon': 'TAMUmake 2020',
    'awards': 'Awards: 1st Place Winner, Accessibility Challenge',
    'description': 'With Valentines day just behind us, we found ourselves thankful for the handwritten notes we had received from our loved ones. Those heart-warming notes are so small, yet so impactful. But it occurred to us that those with visual impairments or blindness may not be able to enjoy and keep these memorable mementos. After looking around online, we realized there was no easy, cheap, affordable way to write out letters in braille. So we knew we had to come up with something Braille-iant. Braille-iant is an end to end solution customized braille printer that adheres with braille standards and interfaces with a web application for easy custom card creation.',
    'hasLink1': true,
    'link1': 'https://devpost.com/software/braille-iant-o1gcya',
    'link1Title': 'Devpost',
    'hasLink2': true,
    'link2': 'https://www.youtube.com/watch?v=mb2KkB-SZ2c&feature=emb_title&ab_channel=DylanTheriot',
    'link2Title': 'YouTube',
    'hasDivider' : true,
    'image': 'braille-iant.png',
  },
  {
    'date': 'January 2020',
    'title': 'Eco-Mode',
    'hackathon': 'TAMUhack 2020',
    'awards': 'Awards: Grand Prize, Credera Best Mobile App, PwC Most Impact, Best Use of Devpost',
    'description': "The three R's have been drilled into our head since birth. Reduce. Reuse. Recycle. Yet the former two are often forgotten by the general population. By 2040, it's predicted that the world will see increased food shortages, more ferocious wildfires, and the potential extinction of coral reefs. With our app, we aim to increase reusability among common, household items with computer vision and web scrapers. Not everything can be recycled. But everything can be reused.",
    'hasLink1': true,
    'link1': 'https://devpost.com/software/eco-mode-9rmfk5',
    'link1Title': 'Devpost',
    'hasLink2': true,
    'link2': 'https://www.youtube.com/watch?v=xw1cjE3D4Fg&feature=emb_title&ab_channel=matrev',
    'link2Title': 'YouTube',
    'hasDivider' : true,
    'image': 'eco-mode.png',
  },
  {
    'date': 'November 2019',
    'title': 'S.O.S.',
    'hackathon': 'HackTX 2019',
    'description': "Everyone in our team was affected by Hurricane Harvey. We found that it was difficult to have an organized way to find help and help others. We wanted to create a peer-to-peer platform that can be used to help those who need help along with giving volunteers a platform to help others. Our audience can be volunteers as well as officials such as police. The idea is to have a bunch of drones that go around and track individuals who need help and send help their way using computer vision.",
    'hasLink1': true,
    'link1': 'https://devpost.com/software/s-o-s',
    'link1Title': 'Devpost',
    'hasLink2': false,
    'hasDivider' : true,
    'image': 'sos.jpg',
  },
  {
    'date': 'October 2019',
    'title': '25-to-5',
    'hackathon': 'T-Mobile Hacktober 2019',
    'awards': 'Awards: Third Place',
    'description': "Developed an AR/VR solution in 24 hours to enhance the customer-employee interaction in Metro by T-Mobile stores. Utilized Unity, Leap Motion, and AWS for an immersive, headset-less solution for quick and easy in-store product browsing.",
    'hasLink1': true,
    'link1': 'https://devpost.com/software/25-to-5',
    'link1Title': 'Devpost',
    'hasLink2': true,
    'link2': 'https://www.youtube.com/watch?v=Hw3QHibU2qE&feature=emb_title&ab_channel=YonathanZetune',
    'link2Title': 'YouTube',
    'hasDivider' : true,
    'image': '25-to-5.jpg',
  },
  {
    'date': 'October 2019',
    'title': 'FiestaFinder',
    'hackathon': 'TAMU Datathon 2019',
    'description': "Given certain user inputs, FiestaFinder would analyze and show the closest and best rated taco/burrito options in the area. You can filter your preferences based off of ingredients, allergies, and more.",
    'hasLink1': true,
    'link1': 'https://devpost.com/software/fiesta-finder',
    'link1Title': 'Devpost',
    'hasLink2': false,
    'hasDivider' : true,
    'image': 'fiestafinder.jpg',
  },
  {
    'date': 'September 2019',
    'title': 'Wildcat',
    'hackathon': 'HowdyHack 2019',
    'awards': 'Awards: Best Original Hack',
    'description': "Wildcat is an app utilizing voice recognition, bluetooth, and a hardware component to encourage Aggies to do their wildcat tradition. When a class year is said, the hardware component begins emitting a loud sound to let the Aggies know to do their wildcat. This sound goes on until the wildcat is performed, and the Aggies can rest assured knowing they are still redass.",
    'hasLink1': true,
    'link1': 'https://devpost.com/software/wildcat',
    'link1Title': 'Devpost',
    'hasLink2': true,
    'link2': 'https://www.youtube.com/watch?v=mrpLiS3KeIU&feature=emb_title&ab_channel=matrev',
    'link2Title': 'YouTube',
    'hasDivider' : true,
    'image': 'wildcat.jpg',
  },
  {
    'date': 'July-August 2019',
    'title': 'Avengers Soundboard',
    'description': "A fun, simple phone soundboard application with over thirty different sounds from the Marvel Cinematic Universie. Built with Flutter and Dart, it was a more complicated version of my very first Flutter app 'Michael's Button'.",
    'hasLink1': true,
    'link1': 'https://github.com/dylantheriot/avengers_soundboard',
    'link1Title': 'GitHub',
    'hasLink2': false,
    'hasDivider' : true,
    'image': 'avengerssoundboard.png',
  },
  {
    'date': 'January 2019',
    'title': 'MyDigiceipt',
    'hackathon': 'TAMUhack 2019',
    'awards': 'Awards: Best Original Hack',
    'description': "My team and I developed an app that would scan digital receipts to be analyzed and stored. Receipts these days are a huge hassle, and we felt there had to be a better way. Although we are still inexperienced, we got to work with Microsoft's Computer Vision, and we even came up with a unique log in utilizing chess. I worked primarily on the backend data creation.",
    'hasLink1': true,
    'link1': 'https://devpost.com/software/mydigiceipt',
    'link1Title': 'Devpost',
    'hasLink2': false,
    'hasDivider' : true,
    'image': 'mydigiceipt.jpg',
  },
  {
    'date': 'October 2018',
    'title': 'The DeMan Within',
    'hackathon': '2018 Chillennium Game Jam',
    'description': "I developed an endless runner game using Unity and C# in 48 hours with a team. The theme of the competition was 'Transform', and I was the Lead Programmer. I learned C#, Unity, and a lot about the game development process.",
    'hasLink1': true,
    'link1': 'https://dtheriot.itch.io/the-deman-within',
    'link1Title': 'itch.io',
    'hasLink2': false,
    'hasDivider' : false,
    'image': 'thedemanwithin.png',
  },
];

export default projects;